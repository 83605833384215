import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { useDrupalJsonApi } from '~/composables/api/drupal-json-api';
import { fancyConsole } from '~/composables/fancy-logger';
import { notEmpty } from '~/common/empty';
import {
  Adjustment,
  CartData,
  OrderData,
  OrderItemData,
  ProductDataBasic,
  ProductVariationDataIncluded,
  ShipmentDataIncluded,
  StoreData,
} from '~/types/json-api/commerce';
import { JsonResponseIncluded } from '~/types/json-api/json-api';
import { DrupalCommercePrice } from '~/types/drupal-common';

interface OrderItemBase {
  id: string,
  title: string,
  type: string,
  // The total, not including item-specific adjustments
  totalPrice: DrupalCommercePrice,
  unitPrice: DrupalCommercePrice,
  adjustmentsTotal: number,
  cartId: string,
  isValid: boolean,
}

export type OrderItemInvalidParsed = OrderItemBase;

export interface OrderItemParsed extends OrderItemBase {
  id: string,
  type: string,
  href: string,
  title: string,
  unitPrice: DrupalCommercePrice,
  adjustments: Adjustment[],
  totalPrice: DrupalCommercePrice,
  adjustedTotal: number,
  img: string,
  productId: string,
  specs: any[],
  cartId: string,
  quantities: {
    current: number,
    currentQtyValid: boolean,
    list: number[] | null,
    max: number | null,
    min: number | null
  },
  isValid: true,
  notes?: string | null
}

export interface CartParsed {
  id: string,
  store: {
    name: string,
    href: string,
    id: string,
    logoSrc: string,
    mail: string
  },
  orderItems: (OrderItemParsed | OrderItemInvalidParsed)[],
  orderTotal: number,
  shipments: ShipmentDataIncluded[],
  taxAdjustments: Adjustment[]
  promotionAdjustments: Adjustment[]
}

export const useParsers = () => {
  const jsonApi = useDrupalJsonApi();

  const parseQuantities = (variation: ProductVariationDataIncluded) => {
    const maxAllowed = variation.attributes.maximum_order_quantity;
    const numberInStock: number | undefined = typeof variation.attributes.field_stock !== 'undefined'
      ? variation.attributes.field_stock.available_stock
      : undefined;
    const alwaysInStock: boolean = !!variation.attributes.commerce_stock_always_in_stock;

    const minQty = variation.attributes.minimum_order_quantity ?? 1;
    try {
      const maxQtyCalculated = (() => {
        if (alwaysInStock) {
          // We need to display something in the event that
          // there are no resolvable values based on stock.
          return maxAllowed || 100;
        }

        if (!numberInStock) {
          throw 'The item is out of stock. It should not exist or be presented in a cart context.';
        }

        if (!(numberInStock || maxAllowed)) {
          throw 'An item must have either a stock quantity or be set as always in stock.';
        }

        if (typeof maxAllowed === 'undefined' || maxAllowed === null || maxAllowed === 0) {
          return numberInStock;
        }

        return numberInStock < maxAllowed
          ? numberInStock
          : maxAllowed;
      })();

      const qtyList = maxQtyCalculated >= minQty
        ? [...Array(maxQtyCalculated - minQty + 1)
          .keys()].map((x) => x + minQty)
        : null;

      return {
        alwaysInStock,
        inStock: alwaysInStock || (numberInStock && numberInStock > 0),
        numberInStock,
        minQty,
        maxQty: maxQtyCalculated,
        qtyList,
      };
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  /**
   * Generates a list of order items for a given cart.
   *
   * @param cart     - The cart data.
   * @param response - The response from which this cart data originates.
   */
  const parseOrderItems = (cart: CartData | OrderData, response: JsonResponseIncluded<CartData[] | OrderData[]>): (OrderItemParsed | OrderItemInvalidParsed)[] => (typeof cart.relationships.order_items.data === 'object'
    ? cart.relationships.order_items.data.map((orderItem): OrderItemParsed | OrderItemInvalidParsed | null => {
      const item: OrderItemData | null = pipe(
        jsonApi.parseIncluded<OrderItemData>(response, orderItem.id),
        E.match(() => null, (x) => x),
      );

      if (!item) {
        return null;
      }

      const purchasedEntityId = item.relationships.purchased_entity.data.id;

      // This is a product-variation
      const purchasedEntity: ProductVariationDataIncluded | null = pipe(
        jsonApi.parseIncluded<ProductVariationDataIncluded>(response, purchasedEntityId),
        E.match(() => null, (x) => x),
      );

      const fallback = {
        id: orderItem.id,
        type: orderItem.type,
        title: item.attributes.title,
        totalPrice: item.attributes.total_price,
        unitPrice: item.attributes.unit_price,
        cartId: cart.id,
        isValid: false,
      };

      // If the purchased entity is null, it is probably due to an
      // access/permissions issue. For some reason, access must have been
      // revoked since the item was added to the order. One reason for this
      // might be that the Store associated with the product removed
      // their available shipping method. This revokes access to products
      // to help ensure that orders are not placed without being able to
      // calculate shipping costs. Return some basic info so that we have
      // some information to render on the cart/checkout page, but not enough
      // that it looks allowable for checkout.
      if (!purchasedEntity) {
        // console.log('no entity')
        return fallback;
      }

      const pid = purchasedEntity.relationships.product_id.data.id;

      // We probably only need the first image,
      // but for now just organize them all.
      const product: ProductDataBasic | null = pipe(
        jsonApi.parseIncluded<ProductDataBasic>(response, pid),
        E.match(() => null, (x) => x),
      );

      if (!product) {
        return fallback;
      }

      const productImgUrls = jsonApi.parseProductImageUrls(response, product, '3_4_sc_600x800');

      const variationQuantities = parseQuantities(purchasedEntity);
      const currentQty: number = Number(item.attributes.quantity);
      const currentQuantityValid = variationQuantities.minQty <= currentQty && currentQty <= variationQuantities.maxQty;

      if (!currentQuantityValid) {
        fancyConsole.error('Found invalid item.');
        variationQuantities.qtyList.push(currentQty);
      }

      const purchaseRequestAdjustments = cart.attributes.order_total.adjustments.filter((x) => x.source_id.includes(orderItem.id));
      const purchaseRequestAdjustmentsTotal = purchaseRequestAdjustments
        .map((x) => Number(x.total.number))
        .reduce((a, b) => a + b, 0);
      const totalPrice = item.attributes.total_price;

      const orderItemData: OrderItemParsed = {
        id: orderItem.id,
        type: orderItem.type,
        href: product.attributes.path.alias,
        title: item.attributes.title,
        unitPrice: item.attributes.unit_price,
        adjustments: purchaseRequestAdjustments,
        totalPrice,
        adjustedTotal: Number(totalPrice.number) + purchaseRequestAdjustmentsTotal,
        img: productImgUrls[0],
        productId: pid,
        specs: product.attributes.field_specifications,
        cartId: cart.id,
        quantities: {
          current: currentQty,
          currentQtyValid: currentQuantityValid,
          list: variationQuantities?.qtyList ?? null,
          max: variationQuantities?.maxQty ?? null,
          min: variationQuantities?.minQty ?? null,
        },
        isValid: true,
      };

      if ('field_allow_notes' in product.attributes
        && product.attributes.field_allow_notes
        && 'field_notes' in item.attributes) {
        orderItemData.notes = item.attributes.field_notes;
      }

      return orderItemData;
    })
      .filter(notEmpty)
    : []);

  return {
    parseQuantities,
    parseOrderItems,

    /**
     * Parse the carts metadata from the response cart data.
     *
     * @param cartsData - The carts data.
     * @param response  - The response from which this cart data originates.
     */
    parseCarts: (cartsData: CartData[] | OrderData[], response: JsonResponseIncluded<any>): CartParsed[] => cartsData.map((cart): CartParsed => {
      // console.log({cart})
      const store: StoreData = response.included.find((include) => {
        const cartStoreId = cart.relationships.store_id.data.id;
        return include.type === 'store--online' && include.id === cartStoreId;
      });

      if (!store) {
        throw 'No store found. Maybe you need to "include" the store_id relationship on your JSON api params.';
      }

      const orderItems = parseOrderItems(cart, response);
      const eitherShipments = jsonApi.parseRelationshipObjects<ShipmentDataIncluded>(response, cart, 'shipments');
      const taxAdjustments = cart.attributes.order_total.adjustments.filter((x) => x.type === 'tax');
      const promotionAdjustments = cart.attributes.order_total.adjustments.filter((x) => x.type === 'promotion');

      return {
        id: cart.id,
        store: {
          name: store.attributes.name,
          id: store.id,
          mail: store.attributes.mail,
          logoSrc: jsonApi.parseImageUrlSingle(response, store, 'field_logo', 'sc_220x220'),
          href: store.attributes.path.alias,
        },
        orderTotal: Number(cart.attributes.order_total.total.number),
        orderItems,
        shipments: pipe(
          eitherShipments,
          E.match(
            () => [],
            (shipments) => shipments || [], // If the rates array is empty, the json interprets the value as null.
          ),
        ),
        taxAdjustments,
        promotionAdjustments,
      };
    }),
  };
};
