/**
 * @file Provides a common way of logging api errors.
 */
import { AxiosError } from 'axios';

// https://simplernerd.com/js-console-colors/
const styles: { [p: string]: any } = {
  base: [
    'color: #fff',
    'background-color: #444',
    'padding: 2px 4px',
    'border-radius: 2px',
  ],
  warning: [
    'color: black',
    'background-color: #C25700',
  ],
  error: [
    'color: #eee',
    'background-color: #900605',
  ],
  success: [
    'background-color: #005b2d',
  ],
};

/**
 * Print the styled log. This shouldn't be exported.
 *
 * @param text  - The text to log
 * @param extra - Options to style the message.
 */
const doLog = (text: any, extra: any[] = []) => {
  let style = `${styles.base.join(';')};`;
  style += extra.join(';'); // Add any additional styles
  style += '; padding: 1em 2em'; // Add any additional styles
  console.log(`%c${text}`, style);
};

/**
 * Fancy log the given text.
 *
 * @param text - The message to log.
 */
const fancyLog = (text: string) => {
  doLog(text, styles.base);
};

/**
 * Fancy log the given warning text.
 *
 * @param text - The message to log.
 */
const fancyWarn = (text: string) => {
  doLog(text, styles.warning);
};

/**
 * Fancy log the given error text.
 *
 * @param text - The message to log.
 */
const fancyError = (text: string) => {
  doLog(text, styles.error);
};

/**
 * Fancy log the given success text.
 *
 * @param text - The message to log.
 */
const fancySuccess = (text: string) => {
  doLog(text, styles.success);
};

export const fancyConsole = {
  log: fancyLog,
  error: fancyError,
  success: fancySuccess,
  warn: fancyWarn,
};
