// https://github.com/vuejs/vue-router/issues/2005#issuecomment-567912997
<template>
  <component
    :is="tag"
    :to="tag === 'router-link' ? to : undefined"
    :href="tag === 'a' ? to : undefined"
    :class="[
      'btn',
      size,
      temperature, {
        'stretch': stretch,
        'unpad-x': unpadX,
        'unpad-y': unpadY}
    ]"
    v-on="shouldSmoothScroll ? {'click': () => smoothScroll($event)} : {}"
  >
    <span class="btn__content">
      <slot />
    </span>
  </component>
</template>

<script setup lang="ts">
import { buttonProps } from '~/components/props';

const props = defineProps(buttonProps);

const tag = !props.link
  ? 'button'
  : props.to && typeof props.to === 'string' && (props.to.startsWith('#') || props.to.startsWith('mailto') || props.to.startsWith('http'))
    ? 'a'
    : 'router-link';
const shouldSmoothScroll = tag === 'a' && props.to.startsWith('#');

const smoothScroll = (e) => {
  const element = e.target.tagName === 'SPAN'
    ? e.target.parentNode
    : e.target;
  const target = element.getAttribute('href');
  if (target && target.startsWith('#')) {
    e.preventDefault();
    document.querySelector(target)
      .scrollIntoView({
        behavior: 'smooth',
      });
  }
};
</script>

<!-- This is not scoped so that we can use the classes in drupal WYSIWYG -->
<style lang="scss">
.btn {
  color: white;
  font-weight: 500;
  border-radius: var(--border-radius--standard);
  display: inline-block;
  text-decoration: none;
  background: var(--button-bg);
  background-size: 150% 100%;
  transition: all .2s ease-in-out;
  cursor: pointer;

  .sk-chase-dot {
    &::before {
      background-color: white;
    }
  }

  // @see https://medium.com/codyhouse/line-height-crop-a-simple-css-formula-to-remove-top-space-from-your-text-9c3de06d7c6f
  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc(-.1 * 0.5em);
  }

  &:hover,
  &:focus {
    filter: unset;
    background-position: 100% 0;
  }

  &:focus {
    outline: var(--button-outline-color) solid 2px;
    outline-offset: 2px;
  }

  &:active {
    animation: press 0.3s 1 linear;
  }

  // https://codepen.io/arjunace/pen/dyMdbyr
  @keyframes press {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.92);
    }
    to {
      transform: scale(1);
    }
  }

  &__content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  &[disabled] {
    background: var(--gradient--disabled);
    animation: unset;
  }

  &.sm {
    padding: var(--space-xxxs) var(--space-xs);
    font-size: var(--text-sm);
  }

  &.md {
    padding: var(--space-xxs) var(--space-std);
    font-size: var(--text-md);
  }

  &.lg {
    padding: var(--space-xs) var(--space-md);
    font-size: var(--text-lg);
  }

  // Color temp themes
  &.warm {
    --button-bg: var(--gradient--on-light--warm);
    --button-outline-color: var(--color--on-light--red);

  }

  &.cool {
    --button-bg: var(--gradient--on-light--cool);
    --button-outline-color: var(--color--on-light--purple);
  }

  &.ghost {
    color: var(--color--copy-secondary);

    &.sm {
      line-height: .5;
    }
  }

  &.unpad-x {
    padding-left: 0;
    padding-right: 0;
  }

  &.unpad-y {
    padding-top: 0;
    padding-right: 0;
  }
}

.stretch {
  width: 100%;
}

// Sizes
.icon {
  font-size: .8em;
}
</style>
